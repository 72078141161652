import React, { useState } from "react";

const Gallery = () => {
  const [activeTab, setActiveTab] = useState("All");

  // Arrays of image paths
  const artImages = Array.from(
    { length: 18 },
    (_, index) => `/art${index + 1}.JPG`
  );
  const exhibitionImages = Array.from(
    { length: 20 },
    (_, index) => `/exhibition${index + 1}.JPG`
  );

  // New arts by Shamim Begum
  const shamimArtImages = Array.from(
    { length: 5 },
    (_, index) => `/art_shamim${index + 1}.JPG`
  );

  // New exhibition videos
  const exhibitionVideos = ["/exhibition_video1.MP4", "/exhibition_video2.MP4"];

  // Combine all arts, including Shamim Begum's
  const allArtImages = [...artImages, ...shamimArtImages];

  // All images and videos for the 'All' tab
  const allMedia = [...allArtImages, ...exhibitionImages, ...exhibitionVideos];

  // Determine which media to display based on the active tab
  let mediaToDisplay = [];
  if (activeTab === "Arts") {
    mediaToDisplay = [
      // Section for other artists
      {
        type: "section",
        title: "Khurshid Alam's Artworks",
        items: artImages.map((src) => ({ type: "image", src })),
      },
      // Section for Shamim Begum
      {
        type: "section",
        title: "Artworks by Shamim Begum",
        items: shamimArtImages.map((src) => ({ type: "image", src })),
      },
    ];
  } else if (activeTab === "Exhibition") {
    mediaToDisplay = [
      ...exhibitionImages.map((src) => ({ type: "image", src })),
      ...exhibitionVideos.map((src) => ({ type: "video", src })),
    ];
  } else {
    // 'All' tab
    mediaToDisplay = [
      ...allArtImages.map((src) => ({ type: "image", src })),
      ...exhibitionImages.map((src) => ({ type: "image", src })),
      ...exhibitionVideos.map((src) => ({ type: "video", src })),
    ];
  }

  return (
    <div className="container mx-auto mt-10 px-4">
      <h1 className="text-3xl font-bold mb-6 text-center">Gallery</h1>
      <div className="flex justify-center mb-6">
        <button
          className={`px-4 py-2 mx-2 rounded ${
            activeTab === "All" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => setActiveTab("All")}
        >
          All
        </button>
        <button
          className={`px-4 py-2 mx-2 rounded ${
            activeTab === "Arts" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => setActiveTab("Arts")}
        >
          Arts
        </button>
        <button
          className={`px-4 py-2 mx-2 rounded ${
            activeTab === "Exhibition"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          }`}
          onClick={() => setActiveTab("Exhibition")}
        >
          Exhibition
        </button>
      </div>

      {activeTab === "Arts" ? (
        // Render sections for Arts tab
        mediaToDisplay.map((section, index) => (
          <div key={index} className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {section.items.map((item, idx) => (
                <div key={idx} className="w-full h-64 bg-gray-200">
                  <img
                    src={item.src}
                    alt={`Art Image ${idx + 1}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        // Render media for 'Exhibition' and 'All' tabs
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {mediaToDisplay.map((item, index) => (
            <div key={index} className="w-full h-64 bg-gray-200">
              {item.type === "image" ? (
                <img
                  src={item.src}
                  alt={`Gallery Item ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              ) : (
                <video
                  src={item.src}
                  controls
                  className="w-full h-full object-cover"
                ></video>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Gallery;
