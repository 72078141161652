import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Link } from "react-router-dom";

const slideImages = ["/art2.JPG", "/exhibition10.JPG", "/exhibition15.JPG"];

const Home = () => {
  return (
    <div>
      <div className="relative bg-white">
        <Slide easing="ease" duration={5000} indicators>
          {slideImages.map((each, index) => (
            <div key={index} className="each-slide h-screen">
              <div
                className="h-full bg-cover bg-center"
                style={{ backgroundImage: `url(${each})` }}
              ></div>
            </div>
          ))}
        </Slide>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-black bg-opacity-50 p-8 rounded shadow-md text-center">
            <h1 className="text-4xl font-bold mb-4 text-white">
              Welcome to New York Art Connection
            </h1>
            <p className="text-lg text-white">
              Join us in our journey to explore and celebrate art and culture.
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto text-center mt-20">
        <h2 className="text-3xl font-bold mb-4">About Us</h2>
        <p className="text-lg mb-4">
          The New York Art Connection is a premier cultural organization
          dedicated to fostering and promoting the appreciation of art and
          culture. Established with the mission to serve as a beacon for
          artistic talent, we provide a dynamic platform for artists and art
          enthusiasts alike. Our initiatives span across organizing art
          exhibitions, cultural events, and educational programs that aim to
          enrich the community and celebrate the diversity of artistic
          expression.
        </p>
        <Link to="/about" className="bg-blue-500 text-white py-2 px-4 rounded">
          See More
        </Link>
      </div>
      {/* <div className="container mx-auto text-center mt-20">
        <h2 className="text-3xl font-bold mb-4">Cultural Fellowship</h2>
        <p className="text-lg mb-4">
          The New York Art Connection's Cultural Fellowship supports aspiring
          artists and cultural enthusiasts from around the world. Providing up
          to 100% financial support, this fellowship fosters global cultural
          exchange and promotes diverse artistic expressions.
        </p>
        <Link
          to="/fellowship"
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Apply Now
        </Link>
      </div> */}

      {/* Gallery Preview Section */}
      <div className="container mx-auto text-center mt-20 px-4">
        <h2 className="text-3xl font-bold mb-4">Gallery</h2>
        <p className="text-lg mb-4">
          Explore our collection of art and exhibitions.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-4">
          {[
            "/art1.JPG",
            "/art2.JPG",
            "/exhibition1.JPG",
            "/exhibition2.JPG",
          ].map((imageSrc, index) => (
            <div key={index} className="w-full h-64 bg-gray-200">
              <img
                src={imageSrc}
                alt={`Gallery Preview ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
        <Link
          to="/gallery"
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          View Full Gallery
        </Link>
      </div>

      <div className="container mx-auto flex flex-col md:flex-row items-center mt-20">
        <div className="md:w-2/3 md:pr-8">
          <h2 className="text-3xl font-bold mb-4">Our Director</h2>
          <p className="text-lg mb-4">
            Khurshid Saleem is a visionary abstract artist, internationally
            acclaimed and an Olympic gold medal and torch winner from the USA
            (Beijing Olympic China - 2008). He is notably educated and the owner
            of an art gallery in Long Island, New York, USA. His honors and
            awards include:
          </p>
          <ul className="list-disc list-inside text-left mb-4">
            <li>
              UNESCO Award, issued by Club Of UNESCO of Greek - Athens, Greece
              (Jan 2013)
            </li>
            <li>
              Participant in Historical Artist Exhibition, issued by Exhibition
              - Vienna, Austria (Jan 2010), alongside works of Pablo Picasso,
              Salvador Dali, Marc Chagall, Victor Vasarely, and Francisco Goya
            </li>
            <li>
              Recognition by House of Representative, issued by The Congress of
              America (Jan 2010)
            </li>
            <li>
              Participant Artist, issued by 100 Excellent Artist Exhibition -
              Malaysia (Jan 2009)
            </li>
            <li>
              Olympic Fine Arts Gold Medal and Torch Winner, issued by
              International Olympic Committee and Ministry of Culture (Aug
              2008), winning artist from the USA in the Beijing 2008 Olympics,
              China
            </li>
            <li>
              Recognition by House of Representative, issued by The Congress of
              America (Jan 2010)
            </li>
          </ul>
        </div>
        <div className="md:w-1/3 mt-8 md:mt-0">
          <img
            src="/Khurshid2.jpg"
            alt="Khurshid Alam Saleem"
            className="rounded shadow-md"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
