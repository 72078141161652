import React from "react";
// import directorImage from "/Khurshid.jpg"; // Ensure this path is correct

const About = () => {
  return (
    <div className="bg-gray-100 py-48">
      <div className="container mx-auto px-4 bg-gray-100">
        <h1 className="text-4xl font-bold mb-8 text-center">About Us</h1>
        <div className="mb-8">
          <p className="text-lg mb-4">
            The New York Art Connection is a premier cultural organization
            dedicated to fostering and promoting the appreciation of art and
            culture. Established with the mission to serve as a beacon for
            artistic talent, we provide a dynamic platform for artists and art
            enthusiasts alike. Our initiatives span across organizing art
            exhibitions, cultural events, and educational programs that aim to
            enrich the community and celebrate the diversity of artistic
            expression.
          </p>
          <p className="text-lg mb-4">
            One of our core missions is to support individuals who are
            passionate about art and culture. Through our comprehensive
            fellowship programs, we offer financial assistance and resources to
            talented artists, enabling them to pursue their creative dreams and
            make meaningful contributions to the cultural landscape. We believe
            that art has the power to transcend boundaries and connect people
            from all walks of life, fostering a global community of creativity
            and inspiration.
          </p>
          <p className="text-lg mb-4">
            At The New York Art Connection, we are committed to curating
            exceptional experiences that highlight the beauty and significance
            of art in society. Our dedication to excellence and innovation
            ensures that we continue to be a leading force in the art world,
            empowering artists and audiences to explore and celebrate the
            transformative power of art.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
