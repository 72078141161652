import React from "react";
// import directorImage from ''; // Ensure this path is correct

const DirectorMessage = () => {
  return (
    <div className="bg-gray-100 py-20">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-center">
          Director's Message
        </h1>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 md:pr-8">
            <p className="text-lg mb-4">
              “The natural inspiration to make art started at the beginning of
              my life; throughout my childhood I wanted to draw and paint. I
              grew up in Bangladesh and, when I was a young adult, I went to art
              school. Many of my teachers had spent time in America and Europe
              and they were fascinated by abstract art. They taught us students
              about it. I was fascinated by the work of Mark Rothko. In 1986, I
              immigrated to New York. While living on Long Island, I became very
              inspired by the ocean, and I started a series of abstract
              paintings called ‘Water Waves.’
            </p>
            <p className="text-lg mb-4">
              “My current series of work is called ‘3D Abstraction: Hidden
              Beauty.’ These paintings are inspired by Rothko’s squares, but
              they have dimension. My goal is to create beauty using composition
              and color; I use the term ‘hidden beauty’ in my painting’s titles
              because their elements are inspired by forms that are found in
              nature, such as flower petals or even the human body. Not everyone
              notices these natural shapes, so their beauty is often hidden.
            </p>
            <p className="text-lg mb-4">
              My roots are in South Asia, but my art philosophy is very Western.
            </p>
            <p className="text-lg mb-4">
              “I have had 81 solo shows in 17 countries, including America,
              Japan, Sweden, Austria, Morocco, China, India, Bangladesh,
              Germany, France, Belgium, Iran, Greece, Netherlands, Italy,
              Uzbekistan and Russia. I won a gold medal award for my art when it
              was exhibited at the 2008 Beijing Olympics.
            </p>
            <p className="text-lg mb-4">
              “I also served as a visiting professor at a university in China,
              where I taught students modern art techniques. I personally know
              more than 5,000 artists from continents all across the globe. From
              2008 to 2020, I owned the New York Art Connection Gallery, where I
              curated over 80 exhibitions. I am hoping to curate an exhibition
              of international artists at Westbury Arts in 2024 or 2025 and, in
              May of 2024, I will have a solo show at the Bayard Cutting
              Arboretum in Islip.
            </p>
            <p className="text-lg mb-4">
              “My roots are in South Asia, but my art philosophy is very
              Western. We are all human beings and we all live in the world,
              which is basically one village. It doesn’t matter where you were
              born or where you work because the internet connects us quickly
              and easily; artists from anywhere can embrace art of any style.
              There are no limits.”
            </p>
            <p className="text-lg font-bold mt-4">KHURSHID ALAM SALEEM</p>
            <p>Director of New York Art Connection</p>
          </div>
          <div className="md:w-1/3 mt-8 md:mt-0">
            <img
              src={"/Khurshid.jpg"}
              alt="Khurshid Alam Saleem"
              className="rounded shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectorMessage;
