import React, { useState } from "react";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here (e.g., sending data to the server)
    setSubmitted(true);
  };

  return (
    <div className="bg-gray-100 py-20 m-0">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-center">Contact Us</h1>
        <div className="mt-4 text-center mb-6">
          <p className="text-lg mb-2">The New York Art Connection</p>
          <p className="text-lg mb-2">67, Subburst Terrace, Central Islip</p>
          <p className="text-lg mb-2">NY 11722, USA</p>
          <p className="text-lg mb-2">
            Email:{" "}
            <a
              href="mailto:thenyartconnection@gmail.com"
              className="text-blue-500 underline"
            >
              thenyartconnection@gmail.com
            </a>
          </p>
          <p className="text-lg mb-2">
            Phone:{" "}
            <a href="tel:+16315327368" className="text-blue-500 underline">
              +1 (631) 532-7368
            </a>
          </p>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-8">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h2 className="text-2xl font-bold mb-4">Our Location</h2>
            <iframe
              title="Office Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.6776791119647!2d-73.19894368459354!3d40.78914274262742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e8335f4d8fcbf1%3A0x2f9a73f4c7c5b65b!2s67%20Sunburst%20Terrace%2C%20Central%20Islip%2C%20NY%2011722%2C%20USA!5e0!3m2!1sen!2sin!4v1626335137812!5m2!1sen!2sin"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="md:w-1/2">
            <h2 className="text-2xl font-bold mb-4">Get in Touch</h2>
            {submitted ? (
              <div className="bg-green-100 text-green-800 p-4 rounded mb-8">
                Your message has been successfully submitted. Thank you for
                contacting us!
              </div>
            ) : (
              <form
                className="bg-white p-8 rounded shadow-md"
                onSubmit={handleSubmit}
              >
                <div className="mb-4">
                  <label className="block text-left mb-2" htmlFor="name">
                    Name
                  </label>
                  <input
                    className="w-full p-2 border rounded"
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-left mb-2" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="w-full p-2 border rounded"
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-left mb-2" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    className="w-full p-2 border rounded"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button
                  className="bg-blue-500 text-white py-2 px-4 rounded"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
